
body {
    background-color: aliceblue;
}

.projects {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-evenly;
}

.projects h2 {
    font-size: 48px;
    color: #e26200;
}

.bookmarks, .quiz {
    text-align: center;
    margin: 0 20px 0 20px;
    max-width: 400px;
}



.project-links a {
    margin: 0px 20px 0px 20px;
    font-size: 24px;
    font-weight: bolder;
}

.tech {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
}

.tech-item {
    margin: 5px 20px;
    color: #e26200;
    font-size: 20px;
    font-weight: bolder;
}

img {
    margin: 20px 0;
    max-width: 400px;
}

.project-description {
    text-align: left;
    margin: 40px 0;
    font-size: 20px;
}