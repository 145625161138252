/* 
Font #e26200
Background aliceBlue
 */
.fa-bars {
  color: #e26200;
}

.header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    background: AliceBlue;
    height: 88px;
    z-index: 50;
  }
  
  .navigation {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 100%;
    padding: 0 0 0 20px;
  }
  
  .logo {
    margin: 3rem;
  }
  
  .logo a{
    color: #e26200;
    text-decoration: none;
    font-size: 48px;
  }
  
  .spacer {
    flex: 1;
  }
  
  .navigation-items ul {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
  }
  
  .navigation-items li {
    padding: 0 0.5rem;
    font-size: 24px;
    font-weight: bolder;
  }
  
  .navigation-items a {
    color: #e26200;
    text-decoration: none;
  }
  
  .navigation-items .menu:hover,
  .navigation-items .menu:active {
    color: 
  }
  
  @media (max-width: 775px) {
    .navigation-items {
      display: none;
    }

    .logo {
      margin: 0 24px;
    }

    .logo a{
      font-size: 32px;
    }
  }
  
  @media (min-width: 776px) {
  
    .toggle-button {
      display: none;
    }
  
    .logo {
      margin-left: 0;
    }

    .logo a{
      font-size: 48px;
    }
  }