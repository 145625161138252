.contact-me {
    text-align: center;
    max-width: 50%;
    margin: 0 auto;
    padding: 20px 0 300px 0;
}

.contact-me h2 {
    font-size: 48px;
    color: #e26200;
    margin: 0 0 30px 0;
}

.contact {
    text-align: left;
    margin: 0 0 10px 30px;
}

.contact a {
    margin: 0 0 0 10px;
}

.far, .fab {
    color: #e26200;

}
/* 
Font #e26200
Background aliceBlue
 */