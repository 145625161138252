
.aboutme {
    max-width: 60%;
    margin: 0 auto;
    padding: 20px 0;
}

.aboutme h2 {
    font-size: 48px;
    color: #e26200;
}

.aboutme p {
    font-size: 20px;
}

